<!--
  PACKAGE_NAME : src\pages\ai\llm\work-config
  FILE_NAME : model-config
  AUTHOR : hpmoon
  DATE : 2024-09-13
  DESCRIPTION : 운영관리 > LLM > LLM Tester > 프로젝트 작업 설정 > 모델 설정
-->
<template>
  <div></div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {

    };
  },

  computed: {
  },

  watch: {
  },

  methods: {
  },

  created() {
  },

  mounted() {
  },
};
</script>